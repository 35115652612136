@import '~antd/dist/antd.dark.css';

.App {
  text-align: center;
  background-color: #001529;
  flex: 1;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #001529;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input {
  border-radius: 10px;
}

.bottom {
  flex: 0;
}

.card {
  background-color: rgb(0, 13, 26);
}

.top {
  flex: 0;
}

.row-container {
  padding-right: 4vw;
  padding-left: 4vw;
  padding-bottom: 8vh;
  overflow: scroll;
  height: 82vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.row-container::-webkit-scrollbar {
  display: none;
}

.ant-table {
  background-color: #051d33 !important;
  font-size: 13px !important;
}

.ant-table:hover {
  background-color: #051d33 !important;
}

thead > tr > th {
  background-color: #051d33 !important;
}

.ant-table-cell-row-hover {
  background-color: #051d33 !important;
}

.ant-table-body {
  background-color: #051d33 !important;
}

.ant-calendar {
  background-color: #051d33 !important;
}

.ant-picker-panel-container {
  background-color: #051d33 !important;
}

.ant-picker-range-arrow {
  color: #051d33 !important;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  background-color: #051d33 !important;
}

.ant-modal-footer { background-color: #051d33; }
.ant-modal-header { background-color: #051d33; }
.ant-select-dropdown { background-color: #051d33; }

.ant-notification-notice { background-color: #051d33; }

.adm-step .adm-step-indicator .adm-step-icon-container {
  background-color: transparent !important;
}

.application-row-container {
  padding-bottom: 8vh;
  overflow: scroll;
  height: 82vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.application-row-container::-webkit-scrollbar {
  display: none;
}







.container {
  width: 100vw;
  background-color: #001529;
  overflow: hidden;
  position: relative;

}

.content {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  display: flex;
}

.cheque-card {
  width: 100%;
  background-color: #e0dfdf;
  margin-top: 2vh;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 5px solid #FFFFFF;
}

.driver-info-row {
margin-bottom: 0.5vh;
}

.profile-display {
margin-bottom: 10vh;
}

.banking-info-row {
margin-bottom: 1vh;
}

.adm-modal-body {
background-color: #051d33 !important;
color: #FFFFFF;
}

.adm-list-body {
background-color: #051d33 !important;
}

.adm-input-element {
color: #FFFFFF;
}

.skeleton-class {
margin-bottom: 0 !important;
margin-top: 0 !important;
height: 1em;
color: #FFFFFF;
width: 100%;
}

.update-row {
margin-bottom: 2vh;
}
.mobile-autocomplete > .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px; 
}

.adm-card { background: #051d33 }